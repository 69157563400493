<template>
<div>
  <div class="error h-group">
    <div class="error_empty">
      <van-empty :image="require('../image/empty.png')" image-size="80px" description="暂无报价信息" />
    </div>
    <div class="error_msg">
      <div class="title">由于以下原因暂无报价</div>
      <div v-if="resData.resultMsgs" class="msg">
        <template v-for="(msg,index) in resData.resultMsgs">
          <p :key="index"><i class="van-icon van-icon-warning" /> {{msg}}</p>
        </template>
      </div>
    </div>
  </div>
  <div class="quote-result_bnt">
    <div class="quote-result_bnt--fixed" >
      <van-button type="primary" block round @click="toPath">修改信息</van-button>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: "Fail",
  components: { },
  data() {
    return {
    };
  },
  props: {
    resData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toPath(){
      this.$router.push({ path: "/miniprogram/quote/normal/base" });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.error{
  &_empty{
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-base, $padding-base);
    }
  }
  &_msg{
    padding: 10px 5px;
    .title{
      font-size: $font-size-lg;
      font-weight: $font-weight-bold-lg;
    }
    .msg p{
      text-indent: -5px;
      padding-left: 12px;
      font-size: $font-size-sm;
      color: $primary-color;
      text-align: justify;
      text-justify: newspaper;
      word-break: break-all;
    }
  }
}

.quote-result{
  &_bnt{
    height: 60px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
  }
  .h-group{
    margin-bottom: 10px;
    padding: 8px;
    background-color: $white;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-base;
  }
}
</style>
