<template>
  <div class="quote-result h-font-lg">
    <van-tabs v-model="currentIndex" type="card" sticky @disabled="handlerDisabled">
      <van-tab  v-for="(item, index) in computedList" :key="index" :title="item.label" :disabled="pageData.tabLoading[item.value]">
        <template #title>
          <span v-if="pageData.tabLoading[item.value]" class="van-loading__spinner van-loading__spinner--circular" style="width:15px;height:15px"><svg viewBox="25 25 50 50" class="van-loading__circular"><circle cx="50" cy="50" r="20" fill="none"></circle></svg></span>
          <span>{{item.label}}</span>
        </template>
        <div :ref="`html2canvas_${item.value}`" class="quote-result_content" :style="`background: ${ !totalShow ? `url(${bgTitlePng}) no-repeat fixed;` : `linear-gradient(to top, #fff, #3bb1a7)` }`">
          <div class="quote-result_head h-flex h-row-between">
            <div><span>{{item.fullLabel}}</span><span>投保方案</span></div>
            <div v-show="!totalShow" @click="share(item.value,item.fullLabel)">
              <van-icon :name="require('@/assets/images/share.png')" />
              <div style="font-size:10px">分享</div>
            </div>
          </div>
          <div class="base h-group">
              <div class="h-cell h-cell--center h-min-height">
                <div class="h-cell__title" style="font-size:20px">{{plateNoName}}</div>
                <div v-if="!totalShow" class="h-cell__value">
                  <van-button type="primary" icon="edit" size="mini" @click="toRisk">调整方案</van-button>
                </div>
              </div>
                <div class="h-cell__content">
                  <div class="warn">
                    <i class="van-icon van-icon-warning" /> {{riskDesc}}
                  </div>
              </div>
          </div>
          <div>
            <div v-if="quoteRes[item.value]">
              <Succes v-if="quoteRes[item.value].resultCode==='1'" :totalShow="totalShow" :resData="quoteRes[item.value]" ref="success" />
              <Fail v-else-if="quoteRes[item.value].resultCode==='0'" :resData="quoteRes[item.value]" />
              <Quote v-else :resData="quoteRes[item.value]" />
            </div>
            <div v-else style="margin:20px 0;text-align:center">
              暂无报价信息
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <van-overlay :show="pageData.show" @click="pageData.show = false">
      <div class="wrapper">
        <img :src="imageUrl" style="max-height:80%" />
      </div>
  </van-overlay>
</div>
</template>
<script>
import domtoimage from '@/views/miniprogram/quote/list/dom-to-image'
import Succes from "./components/succes";
import Fail from "./components/fail";
import Quote from "./components/quote";
import mixinCommon from '../common/mixin/common';
import { mapState } from "vuex";
import { request } from "@/api/service";
import { Toast } from "vant";
import companyCompare from '../common/mixin/company'
import { cloneDeep } from "lodash";
import bgTitlePng from "./image/bg-title.png"
export default {
  name: "QuoteResult",
  components: {Succes, Fail,Quote},
  mixins: [mixinCommon],
  provide() {
    return {
      pageContext: this,
    };
  },
  data() {
    return {
      bgTitlePng,
      pageData: {
        tabLoading:{
          PAIC:false,
          YGBX:false,
          TPIC:false,
          GPIC:false,
          BOCI:false
        },
        show: false
      },
      currentIndex:0,
      computedList:[],
      totalShow: false,
      imageUrl: null
    };
  },
  computed: {
    ...mapState("car", ["quote","quoteRes"]),
    ...mapState("dict", ["dictMap"]),
    plateNoName(){
      if(!(this.quote&&this.quote.vehicle)){
        return ''
      }
      if(this.quote.vehicle.plateFlag){
        return '新车未上牌'
      }
      return this.quote.vehicle.plateNo
    },
    riskDesc(){
      if(!this.quote){
        return ''
      }
      let compulsoryFlag=false
      let compulsoryNum = 0
      if(this.quote.compulsory&&this.quote.compulsory.checked){
        compulsoryFlag = true
        compulsoryNum = 1
      }
      let commercialFlag=false
      let commercialNum = 0
      let commercialStr=""
      if(this.quote.commercial&&this.quote.commercial.checked){
        commercialFlag = true
        const commercialList=this.quote.commercial.riskList.filter(item=>item.riskCheck).map(item=>item.riskName)
        commercialNum = commercialList.length
        commercialStr=`商业险(${commercialList.join('、')})`
      }
      return `已选择投保${compulsoryFlag?'交强险':''}${compulsoryFlag&&commercialFlag?'、':''}${commercialFlag?commercialStr:''}，共${compulsoryNum+commercialNum}项，如需其他选择点击调整方案重新报价`
    }
  },
  async created() {
    this.$store.dispatch("dict/loadDict")
    let INS_COMPANY = cloneDeep(this.dictMap.INS_COMPANY)
    INS_COMPANY.map(item => {
      if (item.value === this.quote.basis.insuredOrgCode) {
        let obj = {}
        companyCompare.map(cItem => {
          obj.value = item.value
          obj.fullLabel = item.label
          if (item.value === cItem.value) {
            obj.label = cItem.name
          }
        })
        this.computedList.push(obj)
      }
    })
    // this.computedList.forEach((item,index)=>{
    //   const insuredOrgCode=this.quote.basis&&this.quote.basis.insuredOrgCode
    //   if(item.value===insuredOrgCode){
    //     this.currentIndex=index
    //   }
    // })
    if (this.$route.query && this.$route.query.type) return
    this.initSubmit()
  },
  watch:{
    quoteRes:function(val) {
    },
  },
  mounted() {
  },
  methods: {
    toRisk(){
      this.$router.push({ path: "/miniprogram/quote/normal/base" });
    },
    handlerDisabled(){
      Toast("报价中，请稍后点击");
    },
    vehicleQuoteIn(companyCode){
      //数据转换
      const reqParam=this.transformReqData(this.quote)
      reqParam.basis.insuredOrgCode=companyCode
      if (reqParam.basis.insuredOrgCode) {
        const nameMap = {};
        companyCompare.map(item => {
          nameMap[item.value] = item.label
        })
        reqParam.basis.insuredOrgName = nameMap[reqParam.basis.insuredOrgCode];
      }
      this.pageData.tabLoading[companyCode] = true
      request({url: "/afis-api-manage/invoke/core/auto/vehicleQuoteIn",method: "post",data: reqParam}).then(async (resParam)=>{
        this.pageData.tabLoading[companyCode] = false
        this.$set(this.quoteRes,companyCode,resParam)
        await this.$store.dispatch("car/setQuoteRes", this.quoteRes);//保存报价结果
      }).catch(async (error)=>{
        this.pageData.tabLoading[companyCode] = false
        this.$set(this.quoteRes,companyCode,{
          resultCode:'0',
          resultMsgs:[error.message]
        })
        await this.$store.dispatch("car/setQuoteRes", this.quoteRes);//保存报价结果
      });
    },
    initSubmit() {
      this.computedList.forEach(item=>{
        if(this.quoteRes[item.value]){
          return false
        }
        this.vehicleQuoteIn(item.value)
      })
    },
    share (code,name) {
      this.saveImage('html2canvas_' + code, name+'报价单')
    },
    // 图片格式转换方法
    dataURLToBlob (dataurl) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    saveImage (divText, imgText) {
      // window.pageYoffset = 0;
      // document.documentElement.scrollTop = 0;
      // document.body.scrollTop = 0;
      const canvasID = this.$refs[divText]&&this.$refs[divText][0]
      const a = document.createElement('a')
      this.totalShow = true
      Toast.loading({
        overlay: true,
        message: "图片保存中..."
      });
      domtoimage.toJpeg(canvasID, { bgcolor: '#3bb1a7', quality: 1 })
      .then(dataUrl => {
        // const blob = this.dataURLToBlob(dataUrl)
        // a.setAttribute('href', URL.createObjectURL(blob))
        // // 这块是保存图片操作  可以设置保存的图片的信息
        // a.setAttribute('download', imgText + '.jpeg')
        // document.body.appendChild(a)
        // a.click()
        // URL.revokeObjectURL(blob)
        // document.body.removeChild(a)
        Toast.clear();
        this.pageData.show =true
        this.imageUrl = dataUrl
        this.totalShow = false
      })
      .catch(error => {
        Toast.clear();
        this.totalShow = false
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.h-cell__content{
  padding: 6px 8px;
  font-size: $font-size-sm;
  ::v-deep .van-row {
    padding:9px 0;
  }
}
.h-cell__title{
  font-weight: $font-weight-bold-lg;
  font-size: $font-size-md;
  span.icon {
    border-radius: $border-radius-sm;
    color: $white;
    padding: 3px;
    margin-right:6px;
  }
}
.h-cell{
  padding: 10px 8px;
}
.base{
  .warn{
    text-indent: -4px;
    padding-left: 12px;
    font-size: $font-size-xs;
    color: $primary-color;
  }
}
.quote-result{
  height: 100vh;
  background-color: $background-color;
  &_head{
    padding: 10px 2px;
    color:$white;
    font-size: $font-size-xl;
    min-height: 37px;
  }
  &_content{
    padding: 10px;
    background-size: 100% 260px;
    background-position-y: 50px;
  }
  &_bnt{
    height: 60px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
    .total{
      font-size: $font-size-md;
      font-weight: $font-weight-bold-lg;
      padding: 5px;
      span{
        font-size: $font-size-xl;
        color: $red;
      }
    }
    .detail{
      font-size: $font-size-sm;
      color: $gray-5;
      span{
        padding:0 5px;
        border-right: $border-color solid $border-width-xs;
        &:last-child{
          border-right-width:0;
        }
      }
    }
  }
  .h-group{
    margin-bottom: 10px;
    padding: 8px;
    border-radius: $border-radius-lg;
    min-height: 105px;
    .h-min-height {
      min-height: 49px;
    }
  }
}
.quote-result ::v-deep{
  .van-sticky{
    padding: 5px 0;
    background-color:$white;
    .van-tabs__nav{
      padding: 5px;
      height: 40px;
      border-color:$primary-color;
      &--card{
        margin: 0 10px;
      }
    }
    .van-tab{
      padding: 10px 0;
      color:$primary-color;
      border:none;
      border-radius:$border-radius-sm;
    }
    .van-tab--active{
      background-color:$primary-color;
      color:$white;
    }
  }
  .van-empty{
    padding:2px 0;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;
}
</style>
