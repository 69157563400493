<template>
<div style="padding-bottom: 50px;">
    <!-- <div class="base h-group" v-if="totalShow">
      <div class="h-cell h-cell--center">
        <div class="h-cell__title" style="font-size:20px">{{plateNoName}}</div>
        <div class="h-cell__value">
        </div>
      </div>
        <div class="h-cell__content">
          <div class="warn">
            <i class="van-icon van-icon-warning" /> {{riskDesc}}
          </div>
      </div>
    </div> -->
    <div v-if="resData.compulsory" class="compulsory h-group">
        <div class="h-cell h-cell--center">
          <div class="h-cell__title">
              <span class="icon" style="background-color:#57ccc2">强</span>交强险
          </div>
        </div>
        <div class="h-cell__content">
          <van-row>
            <van-col span="8"  class="h-text-color_gray-6">保险期间</van-col>
            <van-col span="16">{{dayjs(resData.compulsory.startTime).format('YYYY-MM-DD HH:mm')}} 至 {{dayjs(resData.compulsory.endTime).format('YYYY-MM-DD HH:mm')}}</van-col>
          </van-row>
          <van-row>
            <van-col span="8" class="h-text-color_gray-6">交强险</van-col>
            <van-col span="8">投保</van-col>
            <van-col span="8" class="h-text-right h-price-color">￥<span class="h-font-md">{{resData.compulsory.actualPremium}}</span></van-col>
          </van-row>
          <van-row v-if="resData.compulsory.vehicleTaxPremium">
            <van-col span="8" class="h-text-color_gray-6">车船税</van-col>
            <van-col span="8">纳税</van-col>
            <van-col span="8" class="h-text-right h-price-color">￥<span class="h-font-md">{{resData.compulsory.vehicleTaxPremium}}</span></van-col>
          </van-row>
        </div>
    </div>

    <div v-if="resData.commercial" class="commercial h-group">
      <div class="h-cell h-cell--center">
        <div class="h-cell__title">
            <span class="icon" style="background-color:#ff976a">商</span>商业险
        </div>
      </div>
        <div class="h-cell__content">
          <van-row>
            <van-col span="8"  class="h-text-color_gray-6">保险期间</van-col>
            <van-col span="16">{{dayjs(resData.commercial.startTime).format('YYYY-MM-DD HH:mm')}} 至 {{dayjs(resData.commercial.endTime).format('YYYY-MM-DD HH:mm')}}</van-col>
          </van-row>
          <div v-if="riskList&&riskList.length>0">
            <template v-for="(item,index) in riskList">
                <Commercial :key="index" :riskItem="item" :formData="resData"/>
            </template>
          </div>
      </div>
  </div>
  <div v-if="serviceList&&serviceList.length>0" class="service h-group" style="padding-bottom: 50px;">
    <div class="h-cell h-cell--center">
        <div class="h-cell__title">
          <span class="icon" style="background-color:#07c160">增</span>特约条款
        </div>
    </div>
    <div class="h-cell__content">
        <van-row v-for="(item,index) in serviceList" :key="index">
          <van-col span="16" class="h-text-color_gray-6">{{item.riskName}}</van-col>
          <van-col span="8">
            <span v-if="item.serviceList&&item.serviceList.length>1">
              <ClauseView :list="item.serviceList" />
            </span>
            <span v-else>{{item.quantity}}次</span>
          </van-col>
        </van-row>
    </div>
  </div>
    <TermManage ref="termManage" v-if="resData.basis.insuredOrgCode !== 'TPIC'" :formData="resData" />
  <div class="h-group" v-show="totalShow">
    <div class="total-cla">总保费：<span>￥{{totalPrem}}元</span></div>
    <div class="detail-cla">
      <span v-if="commercialPrem">商业险:{{commercialPrem}}元</span>
      <span v-if="compulsoryPrem">交强险:{{compulsoryPrem}}元</span>
      <span v-if="compulsoryTaxPrem">车船税:{{compulsoryTaxPrem}}元</span>
    </div>
  </div>

  <div class="quote-result_bnt" v-if="!totalShow">
    <div class="quote-result_bnt--fixed" >
      <div class="success-btn" v-if="resData.basis.insuredOrgCode === 'YGBX'">
        <van-button type="primary" size="small" @click="underwriting" v-if="permBtns.includes('C603') && ['0', '2'].includes(resData.basis.insuredStatus)">核&nbsp;&nbsp;保</van-button>
        <van-button type="primary" size="small" @click="collection" v-if="permBtns.includes('C605') && ['0', '2'].includes(resData.basis.insuredStatus) && (resData.holder.custType === '0' || resData.insured.custType === '0')">身份证采集</van-button>
        <van-button type="primary" size="small" @click="toUpload" v-if="resData.basis.insuredOrgCode && ['0', '2', '3'].includes(resData.basis.insuredStatus)">上传资料</van-button>
      </div>
      <div class="total">总保费：<span>￥{{totalPrem}}元</span></div>
      <div class="detail">
        <span v-if="commercialPrem">商业险:{{commercialPrem}}元</span>
        <span v-if="compulsoryPrem">交强险:{{compulsoryPrem}}元</span>
        <span v-if="compulsoryTaxPrem">车船税:{{compulsoryTaxPrem}}元</span>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import dayjs from 'dayjs'
import { mapState } from "vuex";
import { request } from "@/api/service";
import { Toast, Dialog } from 'vant'
import Commercial from '../../components/riskView/Commercial'
import ClauseView from '../../components/riskView/ClauseView'
import TermManage from '@/views/miniprogram/quote/list/termManage/index.vue'
export default {
  name: "Succes",
  components: { Commercial,ClauseView,TermManage },
  data() {
    return {
      serviceRisks:["COMM0F01","COMM0G01","COMM0H01","COMM0J01"],
      permBtns: [],
      productTypeCode: 'COMM',
      commTermData: [],
      compTermData: [],
      termData: [],
      addTermShow: false,
      amountData: ['COMM0A01', 'COMM1A01', 'COMM0A02', 'COMM1A02', 'COMM0A03', 'COMM1A03', 'COMM0B01', 'COMM1B01', 'COMM0B02', 'COMM1B02', 'COMM0B03', 'COMM1B03', 'COMM0C01', 'COMM1C01', 'COMM0C03', 'COMM1C03', 'COMM0D02', 'COMM1D02', 'COMM0B05', 'COMM1B05', 'COMM0C02', 'COMM1C02', 'COMM0M01', 'COMM1M01', 'COMM0M03', 'COMM1M03'],
      unitData: ['COMM0D01', 'COMM1D01', 'COMM0D03', 'COMM1D03', 'COMM0E01', 'COMM1E01']
    };
  },
  props: {
    resData: {
      type: Object,
      default: () => {}
    },
    totalShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("car", ["quote","quoteRes"]),
    ...mapState("user", ["info"]),
    totalPrem(){
      let total=0
      if(this.resData.commercial){
        total+=this.resData.commercial.actualPremium
      }
      if(this.resData.compulsory){
        total+=(this.resData.compulsory.actualPremium+(this.resData.compulsory.vehicleTaxPremium || 0))
      }
      return total.toFixed(2)
    },
    commercialPrem(){
      if(this.resData.commercial){
        return this.resData.commercial.actualPremium
      }
      return null
    },
    compulsoryPrem(){
      if(this.resData.compulsory){
        return this.resData.compulsory.actualPremium
      }
      return null
    },
    compulsoryTaxPrem(){
      if(this.resData.compulsory && this.resData.compulsory.vehicleTaxPremium){
        return this.resData.compulsory.vehicleTaxPremium
      }
      return null
    },
    riskList(){
      if(!(this.resData.commercial && this.resData.commercial.riskList)){
        return []
      }
      return this.resData.commercial.riskList.filter(item =>{
        return this.serviceRisks.indexOf(item.riskCode) === -1
      })
    },
    serviceList(){
      if(!(this.resData.commercial && this.resData.commercial.riskList)){
        return []
      }
      return this.resData.commercial.riskList.filter(item =>{
        return this.serviceRisks.indexOf(item.riskCode) > -1
      })
    },
    plateNoName(){
      if(!(this.quote&&this.quote.vehicle)){
        return ''
      }
      if(this.quote.vehicle.plateFlag){
        return '新车未上牌'
      }
      return this.quote.vehicle.plateNo
    },
    riskDesc(){
      if(!this.quote){
        return ''
      }
      let compulsoryFlag=false
      let compulsoryNum = 0
      if(this.quote.compulsory&&this.quote.compulsory.checked){
        compulsoryFlag = true
        compulsoryNum = 1
      }
      let commercialFlag=false
      let commercialNum = 0
      let commercialStr=""
      if(this.quote.commercial&&this.quote.commercial.checked){
        commercialFlag = true
        const commercialList=this.quote.commercial.riskList.filter(item=>item.riskCheck).map(item=>item.riskName)
        commercialNum = commercialList.length
        commercialStr=`商业险(${commercialList.join('、')})`
      }
      return `已选择投保${compulsoryFlag?'交强险':''}${compulsoryFlag&&commercialFlag?'、':''}${commercialFlag?commercialStr:''}，共${compulsoryNum+commercialNum}项，如需其他选择点击调整方案重新报价`
    }
  },
  created() {
    this.initBtn(this.resData.basis.insuredOrgCode)
    if (this.resData.compulsor) {
        this.resData.compulsory.specialTermList = !this.resData.compulsory.specialTermList ? [] : this.resData.compulsory.specialTermList
        this.resData.compulsory.specialTermList.map(item => {
            this.$set(item, 'isDel', false)
        })
    }
    if (this.resData.commercial) {
        this.resData.commercial.specialTermList = !this.resData.commercial.specialTermList ? [] : this.resData.commercial.specialTermList
        this.resData.commercial.specialTermList.map(item => {
            this.$set(item, 'isDel', false)
        })
    }
    this.productTypeCode = 'COMP'
  },
  mounted() {
  },
  methods: {
    dayjs,
    initData() {
    },
    // 初始化按钮
    async initBtn(insuredOrgCode) {
      const { info } = this.$store.getters
      let dealerId = null
      let orgCode = null
      if (info.busiType === '2') {
        dealerId = info.orgId
        orgCode = info.issueOrgId
      }
      if (info.busiType === '1') {
        dealerId = this.resData.basis.dealerId
        orgCode = this.resData.basis.orgId
      }
      const permBtns = await request({
        url: '/afis-auto-web/auto/config/channel/queryQuoteBtnList',
        method: 'post',
        data: {
          commonApiConfigType: '1',
          orgCode,
          insuredOrgCode,
          businessChannelId: dealerId
        }
      })
      this.permBtns = permBtns || []
    },
    underwriting () {
      let setInter = setInterval(() => {
        Toast.loading({
          forbidClick: true
        }, 500)
      })
      request({
        url: '/afis-api-manage/invoke/core/auto/underwritingIn',
        method: 'post',
        data: {
          subBusinessNo: this.resData.basis.subBusinessNo,
          compSpecialTermList: this.resData.compulsory.specialTermList,
          commSpecialTermList: this.resData.commercial.specialTermList
        }
      }).then(res => {
          let info = this.quoteRes[res.basis.insuredOrgCode]
          for (let i in res) {
              info[i] = res[i]
          }
          this.$set(this.quoteRes, res.basis.insuredOrgCode, info)
          this.$store.dispatch("car/setQuoteRes", this.quoteRes)
          clearInterval(setInter)
          Toast.clear()
          this.$router.push({
            name: 'Underwriting',
            query: {
              id: res.basis.subBusinessNo
            }
          })
      }).catch(e => {
          clearInterval(setInter)
          Toast.clear()
      })
    },
    collection () {
      this.$store.dispatch("car/setHolderInfo", this.quote.holder)
      this.$store.dispatch("car/setInsuredInfo", this.quote.insured)
      this.$router.push({
        name: 'CardCollection'
      })
    },
    toUpload () {
      this.$router.push({
        name: 'UploadFile'
      })
    },
    addTerm () {
        this.addTermShow = true
        this.getList()
    },
    getList () {
        request({
            url: '/afis-auto-web/config/autoCfgSpecialTerm/list',
            method: 'post',
            data: {
                page: 1,
                pageSize: 1000,
                productTypeCode: this.productTypeCode,
                orgId: this.info.orgId,
                insuredOrgCode: this.quote.basis.insuredOrgCode
            }
      }).then(res => {
          res.list.map(item => {
              this.$set(item, 'isEdit', false)
              this.$set(item, 'checked', false)
              this.$set(item, 'isDel', true)
          })
          this.termData = res.list
      })
    },
    edit (ind) {
        if (this.productTypeCode === 'COMP') {
            this.$set(this.resData.compulsory.specialTermList[ind], 'isEdit', !this.resData.compulsory.specialTermList[ind].isEdit)
        } else {
            this.$set(this.resData.commercial.specialTermList[ind], 'isEdit', !this.resData.commercial.specialTermList[ind].isEdit)
        }
    },
    termBlur (ind) {
        if (this.productTypeCode === 'COMP') {
            this.$set(this.resData.compulsory.specialTermList[ind], 'isEdit', false)
        } else {
            this.$set(this.resData.commercial.specialTermList[ind], 'isEdit', false)
        }
    },
    confirm () {
        let selectedData = this.termData.filter(item => item.checked)
        if (!selectedData.length) {
            Toast("请选择数据")
            return false
        }
        if (this.productTypeCode === 'COMP') {
            this.resData.compulsory.specialTermList = this.arrayUnique([...this.resData.compulsory.specialTermList, ...selectedData], 'id')
        } else {
            this.resData.commercial.specialTermList = this.arrayUnique([...this.resData.commercial.specialTermList, ...selectedData], 'id')
        }
        Toast.success("添加成功")
        this.addTermShow = false
    },
    arrayUnique (arr, name) {
        var hash = {};
        return arr.reduce(function (item, next) {
            hash[next[name]] ? '' : hash[next[name]] = true && item.push(next);
            return item;
        }, []);
    },
    delTerm (ind) {
        Dialog.confirm({
            title: '提示',
            message: '确定要删除吗',
        }).then(res => {
            if (this.productTypeCode === 'COMP') {
                this.resData.compulsory.specialTermList.splice(ind, 1)
            } else {
                this.resData.commercial.specialTermList.splice(ind, 1)
            }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.h-cell__content{
  padding: 6px 8px;
  font-size: $font-size-sm;
  ::v-deep .van-row {
    padding:9px 0;
  }
}
.h-cell__title{
  font-weight: $font-weight-bold-lg;
  font-size: $font-size-md;
  span.icon {
    border-radius: $border-radius-sm;
    color: $white;
    padding: 3px;
    margin-right:6px;
  }
}
.h-cell{
  padding: 10px 8px;
}
.quote-result{
  &_bnt{
    height: 60px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
    .total{
      font-size: $font-size-md;
      font-weight: $font-weight-bold-lg;
      padding: 5px;
      span{
        font-size: $font-size-xl;
        color: $red;
      }
    }
    .detail{
      font-size: $font-size-sm;
      color: $gray-5;
      span{
        padding:0 5px;
        border-right: $border-color solid $border-width-xs;
        &:last-child{
          border-right-width:0;
        }
      }
    }
  }
  .h-group{
    margin-bottom: 10px;
    padding: 8px;
    background-color: $white;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-base;
  }
}
.success-btn {
  width: 100%;
  padding: 10px 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
  button {
    min-width: 50px;
    margin-right: 10px;
  }
}
.total-cla{
  font-size: $font-size-md;
  font-weight: $font-weight-bold-lg;
  padding: 5px;
  span{
    font-size: $font-size-xl;
    color: $red;
  }
}
.detail-cla{
  font-size: $font-size-sm;
  color: $gray-5;
  span{
    padding:0 5px;
    border-right: $border-color solid $border-width-xs;
    &:last-child{
      border-right-width:0;
    }
  }
}
.base{
  .warn{
    text-indent: -4px;
    padding-left: 12px;
    font-size: $font-size-xs;
    color: $primary-color;
  }
}
::v-deep .van-tabs__nav--card {
    margin: 0;
}
.term_content {
    position: relative;
    top: -20px;
    .term_tit {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .term_text {
        padding-left: 10px;
        color: rgb(121, 121, 121);
        margin-top: 6px;
        font-size: 14px;
    }
    .van-field {
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .list-item {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
}
.term_content_list {
    padding: 0 10px 10px 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
    .list {
        height: 300px;
        overflow-y: auto;
    }
    .list-item {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
    .list_title {
        font-size: 18px;
    }
    .term_tit {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .term_text {
        padding-left: 10px;
        color: rgb(121, 121, 121);
        margin-top: 6px;
        font-size: 14px;
    }
    .van-field {
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .btn-bottom {
        text-align: center;
        margin-top: 10px;
        button {
            width: 40%;
            display: inline-block;
        }
    }
}
::v-deep .empy-img img {
    width: 100px;
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50%;
    position: relative;
    left: 50px;
}
::v-deep .van-tabs-cla {
    width: 50%;
}
</style>
