<template>
<div>
  <div class="error h-group">
    <div class="error_empty">
      <van-empty :image="require('../image/empty.png')" image-size="80px" description="暂无报价信息" />
    </div>
    <div class="error_msg">
      <div class="title">由于以下原因暂无报价</div>
      <div v-if="resData.resultCode==='5'" class="msg">
          <p><i class="van-icon van-icon-warning" /> 交管验证码确认</p>
      </div>
      <div v-else-if="resData.resultCode==='6'" class="msg">
          <p><i class="van-icon van-icon-warning" /> 保险公司返回，车型二次确认，请选择车型后重新报价</p>
      </div>
      <div v-else class="msg">
          <p><i class="van-icon van-icon-warning" /> 未知原因</p>
      </div>
    </div>
    <VehicleSelect ref="vehicleSelectRef" @selectVehicle="onSelectVehicle"/>
    <CheckCode ref="checkCodeRef" @checkCode="onCheckCode"/>
  </div>
  <div class="quote-result_bnt">
    <div class="quote-result_bnt--fixed" >
      <van-button type="primary" block round @click="agentEvent">{{resData.resultCode==='5'||resData.resultCode==='6'?'重新报价':'修改信息'}}</van-button>
    </div>
  </div>
</div>
</template>
<script>
import VehicleSelect from '../../components/VehicleSelect';
import CheckCode from '../../components/CheckCode';
import { mapState } from "vuex";
import { Toast, Dialog } from "vant";
import { request } from "@/api/service";
export default {
  name: "Quote",
  components: {VehicleSelect,CheckCode },
  mixins: [],
  data() {
    return {
    };
  },
  computed: {
    ...mapState("car", ["quote","quoteRes"]),
  },
  props: {
    resData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    checkCodeEvent(){
      this.$refs.checkCodeRef.open(this.resData)
    },
    vehicleSelectEvent(){
      this.$refs.vehicleSelectRef.open(this.resData)
    },
    agentEvent(){
      if(this.resData.resultCode==='5'){
        this.checkCodeEvent()
      }else if(this.resData.resultCode==='6'){
        this.vehicleSelectEvent()
      }else{
        this.$router.push({ path: "/miniprogram/quote/normal/base" });
      }
    },
    onCheckCode(val){
      const reqParam=this.resData
      //验证码处理
      reqParam.vehicle.jgCheckCode = val; //验证码
      //重新报价
      this.againVehicleQuote(reqParam)
    },
    onSelectVehicle(car){
      const reqParam=this.resData
      //车型处理
      Object.assign(reqParam.vehicle, car);
      //验证码处理
      // reqParam.vehicle.jgCheckCode = null; //交管所验证码
      // reqParam.vehicle.jgCheckNo = null; //验证码 中银需要
      // reqParam.basis.insBusinessNo = null; //验证码需要
      //重新报价
      this.againVehicleQuote(reqParam)
    },
    againVehicleQuote(reqParam) {
      //数据转换
      Toast.loading({
        message: "报价中...",
        forbidClick: true,
      });
      request({
        url: "/afis-api-manage/invoke/core/auto/vehicleQuoteIn",
        method: "post",
        data: reqParam,
      })
        .then(async (resParam) => {
          Toast.clear();
          this.$set(this.quoteRes,reqParam.basis.insuredOrgCode,resParam)
          await this.$store.dispatch("car/setQuoteRes", this.quoteRes); //保存报价结果
        })
        .catch(async (error) => {
          Toast.clear();
          this.$set(this.quoteRes,reqParam.basis.insuredOrgCode,{
            resultCode:'0',
            resultMsgs:[error.message]
          })
          await this.$store.dispatch("car/setQuoteRes", this.quoteRes); //保存报价结果
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.error{
  &_empty{
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-base, $padding-base);
    }
  }
  &_msg{
    padding: 10px 5px;
    .title{
      font-size: $font-size-lg;
      font-weight: $font-weight-bold-lg;
    }
    .msg p{
      text-indent: -5px;
      padding-left: 12px;
      font-size: $font-size-sm;
      color: $primary-color;
      text-align: justify;
      text-justify: newspaper;
      word-break: break-all;
    }
  }
}

.quote-result{
  &_bnt{
    height: 60px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
  }
  .h-group{
    margin-bottom: 10px;
    padding: 8px;
    background-color: $white;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-base;
  }
}
</style>
